import { Col, Form, Row } from "react-bootstrap";
// import StyledDeleteButton from "../../tools/DeleteButton";
import { TSuccessorData, TTrustsData } from "../../../utilities/trustTypes";
import { getDefaultSuccessorData } from "../../../utilities/defaultDataTypes";
import styled from "styled-components";
import MaskedInput from "react-text-mask";

const StyledPersonTypeLabel = styled(Form.Label)`
  color: #4c5637;
  font-size: 20px;
  font-weight: bold;
`;

export const defaultSuccessorData: TSuccessorData = getDefaultSuccessorData();

export default function Successors({
  arrayPosition,
  formData,
  setFormData,
  readOnly,
}: {
  arrayPosition: number;
  formData: TTrustsData;
  setFormData: (trustsData: TTrustsData) => void;
  readOnly?: boolean;
}) {
  const updateCurrentSuccessor = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    key: keyof TSuccessorData
  ) => {
    let value: string | boolean = event.currentTarget.value;

    const successors = [...(formData.successors as TSuccessorData[])];

    if (value === "true" || value === "false") {
      value = !successors[arrayPosition][key];
    }

    const currentSuccessor: TSuccessorData = {
      ...successors[arrayPosition],
      [key]: value,
    };
    successors[arrayPosition] = currentSuccessor;

    setFormData({
      ...formData,
      successors: successors,
    });
  };

  return (
    <>
      {/* <Row>
        <Col>
          <h6>Successors</h6>
        </Col>
        <Col>
          <div className="d-flex justify-content-end">
            <StyledDeleteButton
              formData={formData}
              setFormData={setFormData}
              keyOfArray="successors"
              arrayIndex={arrayPosition}
              size="sm"
              hidden={readOnly}
            />
          </div>
        </Col>
      </Row> */}
      <Col>
        <Form.Group className="mb-3" controlId={`type${arrayPosition}`}>
          <StyledPersonTypeLabel className="fw-bold">{`Successor Trustee ${
            arrayPosition + 1
          }`}</StyledPersonTypeLabel>
          <Form.Control
            name="type"
            type="hidden"
            value={formData.successors?.[arrayPosition].type}
            readOnly={true}
          />
        </Form.Group>
      </Col>
      <Row>
        <Col xs={4}>
          <Form.Group className="mb-3" controlId={"fullName" + arrayPosition}>
            <Form.Label>Full Name</Form.Label>
            <Form.Control
              name="fullName"
              type="text"
              value={formData.successors?.[arrayPosition].fullName}
              onChange={(target) => updateCurrentSuccessor(target, "fullName")}
            />
          </Form.Group>
        </Col>
        <Col xs={8}>
          <Form.Group
            className="mb-3"
            controlId={"fullAddress" + arrayPosition}
          >
            <Form.Label>Full Address</Form.Label>
            <Form.Control
              name="fullAddress"
              type="text"
              value={formData.successors?.[arrayPosition].fullAddress}
              onChange={(target) =>
                updateCurrentSuccessor(target, "fullAddress")
              }
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={4}>
          <Form.Group className="mb-3" controlId={"email" + arrayPosition}>
            <Form.Label>Email</Form.Label>
            <Form.Control
              name="email"
              type="email"
              value={formData.successors?.[arrayPosition].email}
              onChange={(target) => updateCurrentSuccessor(target, "email")}
            />
          </Form.Group>
        </Col>
        <Col xs={3}>
          <Form.Group className="mb-3" controlId={"phone" + arrayPosition}>
            <Form.Label>Phone Number</Form.Label>
            <MaskedInput
              className="form-control"
              mask={[
                "(",
                /[1-9]/,
                /\d/,
                /\d/,
                ")",
                " ",
                /\d/,
                /\d/,
                /\d/,
                "-",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
              ]}
              value={formData.successors?.[arrayPosition].phone}
              onChange={(e) => updateCurrentSuccessor(e, "phone")}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row></Row>
      {/* Broken out address for V2 */}
      {/* <Col>
          <Form.Group className="mb-3" controlId={"street" + arrayPosition}>
            <Form.Label>Street Address</Form.Label>
            <Form.Control
              name="street"
              type="text"
              value={formData.successors?.[arrayPosition].street}
              onChange={(target) => updateCurrentSuccessor(target, "street")}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId={"city" + arrayPosition}>
            <Form.Label>City</Form.Label>
            <Form.Control
              name="city"
              type="text"
              value={formData.successors?.[arrayPosition].city}
              onChange={(target) => updateCurrentSuccessor(target, "city")}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId={"state" + arrayPosition}>
            <Form.Label>State</Form.Label>
            <Form.Control
              name="state"
              type="text"
              value={formData.successors?.[arrayPosition].state}
              onChange={(target) => updateCurrentSuccessor(target, "state")}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId={"zip" + arrayPosition}>
            <Form.Label>Zip</Form.Label>
            <Form.Control
              name="zip"
              type="text"
              value={formData.successors?.[arrayPosition].zip}
              onChange={(target) => updateCurrentSuccessor(target, "zip")}
            />
          </Form.Group>
        </Col> */}
    </>
  );
}
