import { Form, Button, Spinner } from "react-bootstrap";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";

const StyledSuccessMessage = styled.p`
  text-align: center;
  color: #4c5637;
  font-weight: bold;
`;

const StyledErrorMessage = styled.p`
  text-align: center;
  color: red;
  font-weight: bold;
`;

export default function UploadTemplateModal({
  docName,
  uploadTemplate,
}: {
  docName: string;
  uploadTemplate: (
    templateType: string,
    file: File,
    selectedTrustType?: string
  ) => Promise<void>;
}) {
  const [file, setFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [selectedTrustType, setSelectedTrustType] = useState<string>("");

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
    } else {
      setFile(null);
    }
  };

  const handleTrustTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setSelectedTrustType(value);
  };

  const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!file) {
      alert("No File Selected");
      return;
    }

    if (docName === "Avery Labels" && !selectedTrustType) {
      alert("Please select a trust type!");
      return;
    }
    console.log(docName);
    console.log("Upload Modal", selectedTrustType);

    setIsLoading(true);
    setSuccess(null);
    setError(null);
    try {
      await uploadTemplate(docName, file, selectedTrustType);
      setSuccess(`${file.name} template uploaded successfully!`);
    } catch (error) {
      setError("Error uploading file");
    }
    setIsLoading(false);
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Upload {docName} Template</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onFormSubmit} aria-disabled>
          {docName === "Avery Labels" && (
            <Form.Group className="mb-3">
              <Form.Check
                inline
                type="radio"
                label="CPT"
                value="Avery Labels CPT"
                name="trustType"
                onChange={handleTrustTypeChange}
              ></Form.Check>
              <Form.Check
                inline
                type="radio"
                label="SPT"
                value="Avery Labels SPT"
                name="trustType"
                onChange={handleTrustTypeChange}
              ></Form.Check>
            </Form.Group>
          )}
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Control
              type="file"
              name="fileUpload"
              onChange={onFileChange}
              required
            />
          </Form.Group>
          {error && <StyledErrorMessage>{error}</StyledErrorMessage>}
          {success && <StyledSuccessMessage>{success}</StyledSuccessMessage>}
          {!success && (
            <Button
              type="submit"
              disabled={
                isLoading ||
                !file ||
                (docName === "Avery Labels" && !selectedTrustType)
              }
            >
              {isLoading ? (
                <Spinner as="span" animation="border" size="sm" />
              ) : (
                "Upload"
              )}
            </Button>
          )}
        </Form>
      </Modal.Body>
    </>
  );
}
