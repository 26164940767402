import { Col, Form, Row } from "react-bootstrap";
import { TDecedentData, TTrustsData } from "../../../../utilities/trustTypes";
import { getDefaultDecedentData } from "../../../../utilities/defaultDataTypes";
import styled from "styled-components";
import MaskedInput from "react-text-mask";

const StyledPersonTypeLabel = styled(Form.Label)`
  color: #4c5637;
  font-size: 20px;
  font-weight: bold;
`;

export const defaultDecedentData: TDecedentData = getDefaultDecedentData();

export default function Decedent({
  formData,
  setFormData,
  readOnly,
}: {
  formData: TTrustsData;
  setFormData: (trustsData: TTrustsData) => void;
  readOnly?: boolean;
}) {
  const decedentData = { ...defaultDecedentData, ...formData.decedent };

  const updateCurrentDecedent = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    key: keyof TDecedentData
  ) => {
    if (readOnly) return;
    let value: string | boolean = event.currentTarget.value;

    if (value === "true") {
      value = true;
    } else if (value === "false") {
      value = false;
    }
    const updatedDecedent = {
      ...decedentData,
      [key]: value,
    };

    setFormData({
      ...formData,
      decedent: updatedDecedent,
    });
  };
  return (
    <>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="type">
            <StyledPersonTypeLabel>Decedent</StyledPersonTypeLabel>
            <Form.Control
              name="type"
              type="hidden"
              value={decedentData?.type}
              readOnly={true}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="fullName">
            <Form.Label>Full Name</Form.Label>
            <Form.Control
              name="fullName"
              type="text"
              value={decedentData?.fullName}
              onChange={(target) => updateCurrentDecedent(target, "fullName")}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="lastName">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              name="lastName"
              type="text"
              value={decedentData?.lastName}
              onChange={(target) => updateCurrentDecedent(target, "lastName")}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="aka">
            <Form.Label>AKA</Form.Label>
            <Form.Control
              name="aka"
              type="text"
              value={decedentData?.aka}
              onChange={(target) => updateCurrentDecedent(target, "aka")}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="nee">
            <Form.Label>Née</Form.Label>
            <Form.Control
              name="nee"
              type="text"
              value={decedentData?.nee}
              onChange={(target) => updateCurrentDecedent(target, "nee")}
            />
          </Form.Group>
        </Col>
        <Col className="col-auto">
          <Form.Group controlId="newTrustPronoun">
            <Form.Label className="mx-2">Pronoun</Form.Label>
            <Form.Group className="mb-3">
              <Form.Check
                inline
                name="pronoun"
                type="radio"
                label="His"
                value="his"
                checked={formData.decedent?.pronoun === "his"}
                onChange={(target) => updateCurrentDecedent(target, "pronoun")}
              ></Form.Check>
              <Form.Check
                inline
                name="pronoun"
                type="radio"
                label="Her"
                value="her"
                checked={formData.decedent?.pronoun === "her"}
                onChange={(target) => updateCurrentDecedent(target, "pronoun")}
              ></Form.Check>
            </Form.Group>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="dob">
            <Form.Label>Date of Birth</Form.Label>
            <Form.Control
              name="dob"
              type="date"
              value={decedentData?.dob}
              onChange={(target) => updateCurrentDecedent(target, "dob")}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="ssn">
            <Form.Label>Social Security Number</Form.Label>
            <MaskedInput
              name="ssn"
              className="form-control"
              mask={[
                /\d/,
                /\d/,
                /\d/,
                "-",
                /\d/,
                /\d/,
                "-",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
              ]}
              value={decedentData?.ssn}
              onChange={(target) => updateCurrentDecedent(target, "ssn")}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="ein">
            <Form.Label>EIN</Form.Label>
            <Form.Control
              name="ein"
              type="text"
              value={decedentData?.ein}
              onChange={(target) => updateCurrentDecedent(target, "ein")}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="fullAddress">
            <Form.Label>Full Residence Address</Form.Label>
            <Form.Control
              name="fullAddress"
              type="text"
              value={decedentData?.fullAddress}
              onChange={(target) =>
                updateCurrentDecedent(target, "fullAddress")
              }
            />
          </Form.Group>
        </Col>
        <Col xs lg="2">
          <Form.Group className="mb-3" controlId="yearsInResidence">
            <Form.Label>Years in Residence</Form.Label>
            <Form.Control
              name="yearsInResidence"
              type="number"
              value={decedentData?.yearsInResidence}
              onChange={(target) =>
                updateCurrentDecedent(target, "yearsInResidence")
              }
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="city">
            <Form.Label>City Where Decedent Died</Form.Label>
            <Form.Control
              name="city"
              type="text"
              value={decedentData?.city}
              onChange={(target) => updateCurrentDecedent(target, "city")}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="city">
            <Form.Label>County Where Decedent Died</Form.Label>
            <Form.Control
              name="county"
              type="text"
              value={decedentData?.county}
              onChange={(target) => updateCurrentDecedent(target, "county")}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="dod">
            <Form.Label>Date of Death</Form.Label>
            <Form.Control
              name="dod"
              type="date"
              value={decedentData?.dod}
              onChange={(target) => updateCurrentDecedent(target, "dod")}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="day41">
            <Form.Label>Day 41</Form.Label>
            <Form.Control
              name="day41"
              type="text"
              value={decedentData?.day41}
              onChange={(target) => updateCurrentDecedent(target, "day41")}
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
}
