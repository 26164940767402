import { Col, Form, Row } from "react-bootstrap";
import { TRealEstateData, TTrustsData } from "../../../../utilities/trustTypes";
import { getDefaultRealEstateData } from "../../../../utilities/defaultDataTypes";
import styled from "styled-components";

const StyledFormText = styled(Form.Text)`
  margin-top: 0;
  margin-bottom: 0.5rem;
`;

export const defaultRealEstateData: TRealEstateData =
  getDefaultRealEstateData();

export default function TARealEstate({
  formData,
  setFormData,
  readOnly,
}: {
  formData: TTrustsData;
  setFormData: (trustsData: TTrustsData) => void;
  readOnly?: boolean;
}) {
  const updateCurrentTARealEstate = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    key: keyof TRealEstateData
  ) => {
    if (readOnly) return;
    let value: string | boolean = event.currentTarget.value;

    if (value === "true") {
      value = true;
    } else if (value === "false") {
      value = false;
    }
    const updatedRealEstate = {
      ...formData.realEstate,
      [key]: value,
    };

    setFormData({
      ...formData,
      realEstate: updatedRealEstate,
    });
  };
  return (
    <>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="fullAddress">
            <Form.Label>Property Address</Form.Label>
            <Form.Control
              name="fullAddress"
              type="text"
              value={formData.realEstate?.fullAddress}
              onChange={(target) =>
                updateCurrentTARealEstate(target, "fullAddress")
              }
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="city">
            <Form.Label>Property City</Form.Label>
            <Form.Control
              name="city"
              type="text"
              value={formData.realEstate?.city}
              onChange={(target) => updateCurrentTARealEstate(target, "city")}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="county">
            <Form.Label>Property County</Form.Label>
            <Form.Control
              name="county"
              type="text"
              value={formData.realEstate?.county}
              onChange={(target) => updateCurrentTARealEstate(target, "county")}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="apn">
            <Form.Label>Deed APN</Form.Label>
            <Form.Control
              name="apn"
              type="text"
              value={formData.realEstate?.apn}
              onChange={(target) => updateCurrentTARealEstate(target, "apn")}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="documentNumber">
            <Form.Label>Deed Number</Form.Label>
            <Form.Control
              name="documentNumber"
              type="text"
              value={formData.realEstate?.documentNumber}
              onChange={(target) =>
                updateCurrentTARealEstate(target, "documentNumber")
              }
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="description">
            <Form.Label>Property Description</Form.Label>
            <StyledFormText className="d-block mt-0">
              OR TYPE "SEE EXHIBIT A ATTACHED FOR DESCRIPTION OF PROPERTY"
            </StyledFormText>
            <Form.Control
              name="description"
              as="textarea"
              value={formData.realEstate?.description}
              onChange={(target) =>
                updateCurrentTARealEstate(target, "description")
              }
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
}
