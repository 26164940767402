import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useEffect, useState } from "react";
import usePostBatchTemplatesData from "../../hooks/templateHooks/usePostBatchTemplates";
import { TTrustsData } from "../../utilities/trustTypes";
import styled from "styled-components";

// const StyledFormControl = styled(Form.Control)`
//   width: 85%;
// `;

const StyledFormLabel = styled(Form.Label)`
  font-weight: 600;
  color: #4c5637;
`;

export type TTemplateData = {
  value: string;
  name: string;
  isSelected: boolean;
  isDisabled: boolean;
}[];

export default function GenerateBatchTemplateModal({
  handleClose,
  trustId,
  trustName,
  trustAka,
  trustType,
  apiFunction,
}: {
  handleClose: () => void;
  trustId: string;
  trustName: string;
  trustAka?: string;
  trustType: string;
  apiFunction?: (trustsData: TTrustsData) => void;
}) {
  const [formData, setFormData] = useState([
    {
      id: 1,
      value: "vialOfLife",
      name: "Vial of Life",
      isSelected: trustType !== "TA",
      isDisabled: false,
    },
    {
      id: 2,
      value: "binderBasics",
      name: "Binder Basics",
      isSelected: trustType !== "TA",
      isDisabled: false,
    },
    {
      id: 3,
      value: "averyLabels",
      name: "Avery Labels",
      isSelected: true,
      isDisabled: false,
    },
    {
      id: 4,
      value: "trustTransferDeed",
      name: "Trust Transfer Deed",
      isSelected: true,
      isDisabled: false,
    },
    {
      id: 5,
      value: "trustCertificate",
      name: "Trust Certificate",
      isSelected: true,
      isDisabled: false,
    },
    {
      id: 6,
      value: "loveLetter",
      name: "Love Letter",
      isSelected: true,
      isDisabled: false,
    },
    {
      id: 7,
      value: "cpt",
      name: "CPT",
      isSelected: trustType === "CPT",
      isDisabled: trustType !== "CPT",
    },
    {
      id: 8,
      value: "spt",
      name: "SPT",
      isSelected: trustType === "SPT",
      isDisabled: trustType !== "SPT",
    },
    {
      id: 9,
      value: "ta",
      name: "TA",
      isSelected: trustType === "TA",
      isDisabled: trustType !== "TA",
    },
  ]);

  const [hasInitials, setHasInitials] = useState(false);
  const [deactivate, setDeactivate] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  // const [modalState, setModalState] = useState(1);

  const { fetchBatchTemplates, isLoadingBatchTemplates } =
    usePostBatchTemplatesData();

  const isSubmitDeactive = (formData: TTemplateData) => {
    if (Object.values(formData).every((item) => item.isSelected === false)) {
      setDeactivate(true);
    } else {
      setDeactivate(false);
    }
  };

  const handleSelectAll = (event: any) => {
    const checked = event.target.checked;
    setSelectAll(checked);
    setFormData(
      formData.map((item) => {
        if (!item.isDisabled) {
          return { ...item, isSelected: checked };
        } else {
          return { ...item, isSelected: false };
        }
      })
    );
  };

  useEffect(() => {
    const isAllSelected = (formData: TTemplateData) => {
      if (!selectAll) {
        const totalSelectionCount =
          formData.length - formData.filter((j) => j.isDisabled).length;
        if (
          formData.filter((i) => {
            return i.isSelected;
          }).length === totalSelectionCount
        ) {
          setSelectAll(true);
        }
      }
    };

    isSubmitDeactive(formData);
    isAllSelected(formData);
  }, [formData, selectAll]);

  const handleSubmit = () => {
    fetchBatchTemplates(formData, trustId, trustName, hasInitials);

    if (!isLoadingBatchTemplates) handleClose();
  };

  const handleCheckboxChange = (id: any) => {
    const updatedItems = formData.map((item) =>
      item.id === id ? { ...item, isSelected: !item.isSelected } : item
    );

    setFormData(updatedItems);

    setSelectAll(updatedItems.every((item) => item.isSelected));
  };

  const handleInitialsCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setHasInitials(event.target.checked);
  };

  // const handleNext = () => {
  //   if (modalState === 1) {
  //     setModalState(2);
  //   } else {
  //     handleSubmit();
  //   }
  // };

  // const handleBack = () => setModalState(1);

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>
          {/* {modalState === 1 ? "Trust Details" : "Generate Templates"} */}
          Generate Templates
        </Modal.Title>
      </Modal.Header>
      {isLoadingBatchTemplates ? (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner
            animation="border"
            role="status"
            variant="primary"
            style={{ margin: "20px 0" }}
          />
        </div>
      ) : (
        <>
          <Modal.Body>
            {/* {modalState === 1 ? (
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <StyledFormLabel>
                      Select Preferred Trust Name
                    </StyledFormLabel>
                    <Form.Group>
                      <Form.Check
                        inline
                        name="preferredTrustName"
                        type="radio"
                        label={trustName}
                        value="true"
                      />
                      <Form.Check
                        inline
                        name="preferredTrustName"
                        type="radio"
                        label={trustAka}
                        value="true"
                      />
                    </Form.Group>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <StyledFormLabel>Trust Signing Date</StyledFormLabel>
                    <StyledFormControl name="eventDate" type="date" />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <StyledFormLabel>Select Witnesses</StyledFormLabel>
                    <Form.Check
                      name="witnesses"
                      type="checkbox"
                      label="Carol Mariani"
                    />
                    <Form.Check
                      name="witnesses"
                      type="checkbox"
                      label="Marco Mariani"
                    />
                    <Form.Check
                      name="witnesses"
                      type="checkbox"
                      label="Michaela Mariani"
                    />
                  </Form.Group>
                </Col>
              </Row>
            ) : ( */}
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Check
                    type="checkbox"
                    label="Select All"
                    checked={selectAll}
                    onChange={handleSelectAll}
                  />
                  {formData.map((item) => (
                    <Form.Check
                      key={item.id}
                      style={{ marginLeft: "24px" }}
                      type="checkbox"
                      label={item.name}
                      checked={item.isSelected}
                      value={item.value}
                      disabled={item.isDisabled}
                      onChange={() => handleCheckboxChange(item.id)}
                    />
                  ))}
                </Form.Group>
                {trustType !== "TA" && (
                  <Form.Group className="mb-3">
                    <StyledFormLabel>
                      Add Initials to Trust and Will
                    </StyledFormLabel>
                    <Form.Check
                      type="checkbox"
                      label="Add Initials"
                      checked={hasInitials}
                      onChange={handleInitialsCheckboxChange}
                    />
                  </Form.Group>
                )}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            {/* {modalState === 1 ? ( */}
            {/* <Button variant="primary" onClick={handleNext}>
                Next
              </Button> */}
            {/* <>
                <Button variant="secondary" onClick={handleBack}>
                  Back
                </Button> */}
            <Button
              variant="primary"
              disabled={deactivate}
              onClick={handleSubmit}
            >
              Generate
            </Button>
            {/* </> */}
          </Modal.Footer>
        </>
      )}
    </>
  );
}
