import { Col, Form, Row } from "react-bootstrap";
// import StyledDeleteButton from "../../tools/DeleteButton";
import { TTrusteeData, TTrustsData } from "../../../../utilities/trustTypes";
import { getDefaultTrusteeData } from "../../../../utilities/defaultDataTypes";
import styled from "styled-components";
import MaskedInput from "react-text-mask";

const StyledHeadlineH5 = styled.h5`
  color: #4c5637;
  font-weight: bold;
`;

const StyledPersonTypeLabel = styled(Form.Label)`
  color: #4c5637;
  font-size: 20px;
  font-weight: bold;
`;

export const defaultTrusteeData: TTrusteeData = getDefaultTrusteeData();

export default function Trustees({
  arrayPosition,
  formData,
  setFormData,
  readOnly,
}: {
  arrayPosition: number;
  formData: TTrustsData;
  setFormData: (trustsData: TTrustsData) => void;
  readOnly?: boolean;
}) {
  const updateCurrentTrustee = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    key: keyof TTrusteeData
  ) => {
    let value: string | Boolean = event.currentTarget.value;

    const trustees = [...(formData.trustees as TTrusteeData[])];

    if (value === "true" || value === "false") {
      value = !trustees[arrayPosition][key];
    }

    const currentTrustee: TTrusteeData = {
      ...trustees[arrayPosition],
      [key]: value,
    };
    trustees[arrayPosition] = currentTrustee;

    setFormData({
      ...formData,
      trustees: trustees,
    });
  };

  return (
    <>
      {/* Note: I left this to add Delete functionality later */}
      {/* <Row>
        <Col>
          <h6>Trustees</h6>
        </Col>
        <Col>
          <div className="d-flex justify-content-end">
            <StyledDeleteButton
              formData={formData}
              setFormData={setFormData}
              keyOfArray="trustees"
              arrayIndex={arrayPosition}
              size="sm"
              hidden={readOnly}
            />
          </div>
        </Col>
      </Row> */}
      <Col>
        {/* TO DO: Only show if type is trustee */}
        <Form.Group className="mb-3" controlId={"type" + arrayPosition}>
          <StyledPersonTypeLabel>Trustee</StyledPersonTypeLabel>
          <Form.Control
            name="type"
            type="hidden"
            value="Trustee"
            readOnly={true}
          />
        </Form.Group>
      </Col>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId={"fullName" + arrayPosition}>
            <Form.Label>Full Name</Form.Label>
            <Form.Control
              name="fullName"
              type="text"
              value={formData.trustees?.[arrayPosition].fullName}
              onChange={(target) => updateCurrentTrustee(target, "fullName")}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId={"lastName" + arrayPosition}>
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              name="lastName"
              type="text"
              value={formData.trustees?.[arrayPosition].lastName}
              onChange={(target) => updateCurrentTrustee(target, "lastName")}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs lg="4">
          <Form.Group className="mb-3" controlId={"aka" + arrayPosition}>
            <Form.Label>AKA</Form.Label>
            <Form.Control
              name="aka"
              type="text"
              value={formData.trustees?.[arrayPosition].aka}
              onChange={(target) => updateCurrentTrustee(target, "aka")}
            />
          </Form.Group>
        </Col>
        <Col xs lg="4">
          <Form.Group className="mb-3" controlId={"nee" + arrayPosition}>
            <Form.Label>Née</Form.Label>
            <Form.Control
              name="nee"
              type="text"
              value={formData.trustees?.[arrayPosition].nee}
              onChange={(target) => updateCurrentTrustee(target, "nee")}
            />
          </Form.Group>
        </Col>
        <Col xs lg="2">
          <Form.Group className="mb-3" controlId={"dob" + arrayPosition}>
            <Form.Label>Date of Birth</Form.Label>
            <Form.Control
              name="dob"
              type="date"
              value={formData.trustees?.[arrayPosition].dob}
              onChange={(target) => updateCurrentTrustee(target, "dob")}
            />
          </Form.Group>
        </Col>
        <Col xs lg="2">
          <Form.Group className="mb-3" controlId={"age" + arrayPosition}>
            <Form.Label>Age</Form.Label>
            <Form.Control
              name="age"
              type="number"
              value={formData.trustees?.[arrayPosition].age}
              onChange={(target) => updateCurrentTrustee(target, "age")}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group
            className="mb-3"
            controlId={"relationship" + arrayPosition}
          >
            <Form.Label>Relationship to Deceased</Form.Label>
            <Form.Control
              name="relationship"
              type="text"
              value={formData.trustees?.[arrayPosition].relationship}
              onChange={(target) =>
                updateCurrentTrustee(target, "relationship")
              }
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId={"email" + arrayPosition}>
            <Form.Label>Email</Form.Label>
            <Form.Control
              name="email"
              type="email"
              value={formData.trustees?.[arrayPosition].email}
              onChange={(target) => updateCurrentTrustee(target, "email")}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId={"phone" + arrayPosition}>
            <Form.Label>Phone Number</Form.Label>
            <MaskedInput
              name="phone"
              className="form-control"
              mask={[
                "(",
                /[1-9]/,
                /\d/,
                /\d/,
                ")",
                " ",
                /\d/,
                /\d/,
                /\d/,
                "-",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
              ]}
              value={formData.trustees?.[arrayPosition].phone}
              onChange={(target) => updateCurrentTrustee(target, "phone")}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group
            className="mb-3"
            controlId={"driverLicenseNumber" + arrayPosition}
          >
            <Form.Label>Driver's License Number</Form.Label>
            <Form.Control
              name="driverLicenseNumber"
              type="text"
              value={formData.trustees?.[arrayPosition].driverLicenseNumber}
              onChange={(target) =>
                updateCurrentTrustee(target, "driverLicenseNumber")
              }
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group
            className="mb-3"
            controlId={"driverLicenseExpirationDate" + arrayPosition}
          >
            <Form.Label>Driver's License Expiration Date</Form.Label>
            <Form.Control
              name="driverLicenseExpirationDate"
              type="date"
              value={
                formData.trustees?.[arrayPosition].driverLicenseExpirationDate
              }
              onChange={(target) =>
                updateCurrentTrustee(target, "driverLicenseExpirationDate")
              }
            />
          </Form.Group>
        </Col>
      </Row>
      <hr />
      <Row className="mb-3">
        <StyledHeadlineH5>Address</StyledHeadlineH5>
      </Row>
      <Row>
        <Col>
          <Form.Group
            className="mb-3"
            controlId={"fullAddress" + arrayPosition}
          >
            <Form.Label>Full Address</Form.Label>
            <Form.Control
              name="fullAddress"
              type="text"
              value={formData.trustees?.[arrayPosition].fullAddress}
              onChange={(target) => updateCurrentTrustee(target, "fullAddress")}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId={"street" + arrayPosition}>
            <Form.Label>Street Address</Form.Label>
            <Form.Control
              name="street"
              type="text"
              value={formData.trustees?.[arrayPosition].street}
              onChange={(target) => updateCurrentTrustee(target, "street")}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId={"city" + arrayPosition}>
            <Form.Label>City</Form.Label>
            <Form.Control
              name="city"
              type="text"
              value={formData.trustees?.[arrayPosition].city}
              onChange={(target) => updateCurrentTrustee(target, "city")}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId={"state" + arrayPosition}>
            <Form.Label>State</Form.Label>
            <Form.Control
              name="state"
              type="text"
              value={formData.trustees?.[arrayPosition].state}
              onChange={(target) => updateCurrentTrustee(target, "state")}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId={"zip" + arrayPosition}>
            <Form.Label>Zip</Form.Label>
            <Form.Control
              name="zip"
              type="text"
              value={formData.trustees?.[arrayPosition].zip}
              onChange={(target) => updateCurrentTrustee(target, "zip")}
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
}
