import {
  Row,
  Table,
  Button,
  ButtonToolbar,
  Col,
  Container,
  Modal,
} from "react-bootstrap";
import { UrlPaths } from "../../utilities/types";
import { useEffect, useState } from "react";
import styled from "styled-components";
import DataListPagination from "../tools/ListPagination";
import DataListSearch from "../tools/ListSearch";
import useGetTrustData, {
  TTrustData,
} from "../../hooks/trustHooks/useGetTrustData";
import GenerateBatchTemplatesModal from "./GenerateBatchTemplatesModal";
import { IoArrowDown, IoArrowUp } from "react-icons/io5";

const StyledContainer = styled(Container)`
  width: 85vw;
  border-radius: 8px;

  h3 {
    border: none;
  }
`;

const TrustsTable = styled(Table)`
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 8px;
  overflow: hidden;

  th,
  td {
    padding: 10px 14px;
    border-bottom: 1px solid #e0e0e0;
    text-align: center;
    font-weight: bold;
  }

  tr:last-child {
    th,
    td {
      border-bottom: none;
    }
  }

  td:nth-child(1) {
    text-align: left;
    width: 35%;
  }

  thead th {
    background-color: #f5f5f5;
    font-weight: bold;
    text-align: center;
  }

  tbody tr {
    cursor: pointer;
  }

  th {
    text-align: center;
  }
  th:nth-child(2) {
    width: 15%;
  }
  th:nth-child(3) {
    width: 15%;
  }
  th:nth-child(4) {
    width: 20%;
  }
  th:nth-child(5) {
    width: 2%;
  }
  th:nth-child(6) {
    width: 2%;
  }
  th:nth-child(7) {
    width: 2%;
  }

  .button-container {
    display: flex;
    justify-content: center;
  }

  .action-button {
    padding: 6px 12px;
    font-size: 0.9em;
  }
`;

type Column = {
  label: string;
  field: string;
};

const columns: Column[] = [
  { label: "Trust Name", field: "name" },
  { label: "Trust Type", field: "type" },
  { label: "Surname", field: "familyName" },
  { label: "Trust Date", field: "date" },
  // { label: "Signing Date", field: "signingDate" },
];

const defaultTrustData: TTrustData = {
  id: "0",
  name: "",
  type: "",
  date: "",
  familyName: "",
  trustAka: "",
  signingDate: "",
};

export default function Trusts() {
  const { trustData, totalPages, isLoading, error, fetchData } =
    useGetTrustData();
  console.log("Trust Data", trustData);

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [orderBy, setOrderBy] = useState<string | null>(null);
  const [ascending, setAscending] = useState<boolean | null>(null);
  const [inProgressSearchTerm, setInProgressSearchTerm] = useState<string>("");
  const [selectedTrust, setSelectedTrust] =
    useState<TTrustData>(defaultTrustData);
  const [displayTemplateModal, setDisplayTemplateModal] =
    useState<boolean>(false);

  useEffect(() => {
    fetchData(pageNumber, searchTerm, null, orderBy, ascending);
  }, [fetchData, pageNumber, searchTerm, orderBy, ascending]);

  const trustTableData = trustData ?? [];
  console.log("Trust Table Data", trustTableData);

  // const capitalizeWords = (input_str: string | null) => {
  //   if (input_str) {
  //     return input_str
  //       .toLocaleLowerCase()
  //       .replace(/\b\w/g, (char: string) => char.toUpperCase());
  //   }
  //   return "N/A";
  // };

  const toggleTemplateModal = () => setDisplayTemplateModal((p) => !p);

  const handleOpenModal = (trust: TTrustData) => {
    setSelectedTrust(() => trust);
    toggleTemplateModal();
  };

  const handleSort = (orderByType: string) => {
    const isSameField = orderBy === orderByType;
    const newAscending = isSameField ? !ascending : true;
    setOrderBy(orderByType);

    setAscending(newAscending);

    fetchData(pageNumber, searchTerm, null, orderByType, ascending);
  };

  return (
    <StyledContainer fluid="md">
      <Row className="justify-content-center" style={{ marginTop: "24px" }}>
        <Row className="justify-content-center">
          <Col className="col-auto">
            <h2>Trusts</h2>
          </Col>
        </Row>
      </Row>
      <ButtonToolbar className="justify-content-between w-100 p-0">
        <DataListSearch
          setSearchTerm={setSearchTerm}
          setPageNumber={setPageNumber}
          inProgressSearchTerm={inProgressSearchTerm}
          setInProgressSearchTerm={setInProgressSearchTerm}
          page={"Trusts"}
        />
        <DataListPagination
          totalPages={totalPages}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
        />
      </ButtonToolbar>
      <TrustsTable>
        <thead>
          <tr>
            {columns.map(({ label, field }) => (
              <th
                key={field}
                onClick={() => handleSort(field)}
                style={{ cursor: "pointer" }}
              >
                {"   "}
                {label}
                {orderBy === field && (
                  <span>{ascending ? <IoArrowUp /> : <IoArrowDown />}</span>
                )}
              </th>
            ))}

            <th>View</th>
            <th>Edit</th>
            <th>Generate</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <th>Loading... </th>
            </tr>
          ) : trustTableData.length > 0 ? (
            trustTableData.map((data: TTrustData) => (
              <tr key={data.id}>
                <td>{data.name.toLocaleUpperCase()}</td>
                <td>{data.type}</td>
                <td>{data.familyName.toLocaleUpperCase()}</td>
                <td>{data.date}</td>
                {/* <td>{data.signingDate}</td> */}
                <td>
                  <Button href={UrlPaths.ViewTrust + data.id}>View</Button>
                </td>
                <td>
                  <Button href={UrlPaths.EditTrust + data.id}>Edit</Button>
                </td>
                <td>
                  <Button onClick={() => handleOpenModal(data)}>
                    Generate
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <th>No data found</th>
            </tr>
          )}
        </tbody>
      </TrustsTable>
      <ButtonToolbar className="w-100 p-0">
        <Button className="m-3" href={UrlPaths.NewTrust}>
          + New Trust
        </Button>
      </ButtonToolbar>
      <Modal
        centered
        show={displayTemplateModal}
        onHide={toggleTemplateModal}
        size="sm"
        contentClassName="generate-template-modal"
      >
        <GenerateBatchTemplatesModal
          handleClose={toggleTemplateModal}
          trustId={selectedTrust.id}
          trustName={selectedTrust.name}
          trustAka={selectedTrust.trustAka}
          trustType={selectedTrust.type}
        />
      </Modal>
      {error != null ? <div>The error is: {error.message}</div> : null}
    </StyledContainer>
  );
}
